<template>
  <v-app>
    <v-main class="page">
      <v-container>
        <div class="component">
          <v-icon style="font-size: 200px">mdi-shield-alert-outline</v-icon>
        </div>
        <div class="text-center">
          <h2>Sua conta foi desativada</h2>

          <br />
          <h3>Suporte: Seg – Sex 9h às 18h</h3>
        </div>

        <div class="d-flex justify-center mt-4">
          <v-btn @click="openFacebook()" text icon color="primary">
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn @click="openInstagram()" text icon color="primary">
            <v-icon>mdi-instagram</v-icon></v-btn
          >
          <v-btn @click="openWhatsapp()" text icon color="primary">
            <v-icon>mdi-whatsapp</v-icon>
          </v-btn>
          <v-btn
            href="mailto:contato@psicoplanner.com.br"
            text
            icon
            color="primary"
          >
            <v-icon>mdi-email</v-icon>
          </v-btn>
        </div>
        <div class="d-flex justify-center mt-4">
          <v-btn @click="logout()" color="primary" dark>Sair</v-btn>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  methods: {
    openFacebook() {
      window.open("https://www.facebook.com/psicoplanner");
    },
    openInstagram() {
      window.open("https://www.instagram.com/psicoplanner.app/");
    },
    openEmail() {
      window.open("");
    },
    openWhatsapp() {
      window.open("https://api.whatsapp.com/send?phone=+5521974700948");
    },

    logout() {
      this.$auth.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="sass" scoped>
.page
  background-color: #F0EFF8
.component
  margin-top: 5%
  display: flex
  align-items: center
  justify-content: center
  align-content: center
</style>